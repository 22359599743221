<template>
	<!-- 商户资金总览 -->
	<div class="store-fund-pandect">
		<div class="sfp-cont">
			<div class="sfp-cont-left">
				<div class="cl-top">
					<div class="clt-title1">账户概览</div>
					<div class="clt-title2">可用店铺余额（元）</div>
					<div class="clt-money">
						<span v-if="money">￥{{money.bank}} </span>
					</div>
				</div>
				<div class="cl-btm">
					<div class="cl-btm-item">
						<div>冻结金额（元）</div>
						<div>
							<span v-if="money">{{money.frozen}}</span>
						</div>
					</div>
					<div class="cl-btm-item">
					</div>
				</div>
			</div>
			<div class="sfp-cont-right">
				<div class="cr-top">
				</div>
				
				<div class="cr-btm">
					<div class="cr-btm-item">
						<div>
							<div class="crb-icon"><span class="ri-shield-check-line ri-2x"></span></div>
						</div>
						<div>店铺认证</div>
					</div>
					<div class="cr-btm-item">
						<div>
							<div class="crb-icon"><span class="ri-file-list-2-line ri-2x"></span></div>
						</div>
						<div>入驻协议</div>
					</div>
					<div class="cr-btm-item">
						<div>
							<div class="crb-icon"><span class="ri-copper-coin-line ri-2x"></span></div>
						</div>
						<div>保证金</div>
					</div>
					
				</div>
			</div>
			
		</div>
		
		<div class="el-content">
			<div class="mb12">
				<span>收支情况:</span>
				<a-radio-group v-model:value="type" button-style="solid" @change="changeType">
					<a-radio-button :value="1">本月</a-radio-button>
					<a-radio-button :value="0">上月</a-radio-button>
				</a-radio-group>
			</div>
			<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
				{title:'日期',dataIndex:'date'},
				{title:'账户ID',dataIndex:'gold_id'},
				{title:'收入',dataIndex:'amount_in',slots:{customRender:'amount_in'}},
				{title:'支出',dataIndex:'amount_out',slots:{customRender:'amount_out'}},
				{title:'总收入',dataIndex:'all_amount_in',slots:{customRender:'all_amount_in'}},
				{title:'总支出',dataIndex:'all_amount_out',slots:{customRender:'all_amount_out'}},
			]" >
				<template #amount_in="{record}">
					<span>{{record.amount_in || '0'}}</span>
				</template>
				<template #amount_out="{record}">
					<span>{{record.amount_out || '0'}}</span>
				</template>
				<template #all_amount_in="{record}">
					<span>{{record.total_in || '0'}}</span>
				</template>
				<template #all_amount_out="{record}">
					<span>{{record.total_out || '0'}}</span>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getTradeCountToDayList(info.page,e)}"
					@change="(e)=>{getTradeCountToDayList(e,info.limit)}"
				/>
			</div>
		</div>
		
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import userModel from '@/api/user'
import shopModel from '@/api/addons/shop'
export default{
	setup(){
		const state = reactive({
			money:null,
			type:1,
			info:{
				list:[],
				count:0,
				page:1,
				limit:10
			}
		})

		shopModel.getShopUserDetail(res=>{
			state.shopInfo = res

			shopModel.getShopMoney(res.shop.shop_id,res=>{
				state.money = res
				getTradeCountToDayList(1,state.info.limit)
			})
		})

		function changeType(type){
			getTradeCountToDayList(1,state.info.limit);
		}

		function getTradeCountToDayList(page,limit){
			userModel.getTradeCountToDayList(page,limit,{gold_id:state.money.id,type:state.type},res=>{
				state.info = {limit,...res}
			})
		}

		return{
			...toRefs(state),
			changeType,
			getTradeCountToDayList
		}
	}
}
</script>

<style lang="scss">
	.sfp-cont{
		width: 99%;
		display: flex;
		justify-content: space-between;
		&-left{
			width: 60%;
			background: #fff;
			height: 350px;
			
			.cl-top{
				width: 98%;
				padding-left: 24px;
				padding-top: 24px;
				height: 200px;
				border-bottom: 1px solid #F4F4F4;
			}
			
			.clt-title1{
				font-size: 18px;
				margin-bottom: 24px;
			}
			.clt-title2{
				color: #999;
				font-size: 14px;
				margin: 12px 0;
			}
			.clt-money{
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				span:first-child{
					font-size: 28px;
				}
			}
			
			.cl-btm{
				display: flex;
				padding-left: 24px;
				&-item{
					width: 50%;
					padding-top: 24px;
					
					div:first-child{
						color: #999;
						margin-bottom: 12px;
					}
					div:last-child{
						display: flex;
						align-items: center;
						
						span:first-child{
							font-size: 28px;
						}
					}
				}
			}
		}
		&-right{
			width: 38%;
			background: #fff;
			height: 350px;
			
			.cr-top{
				padding-left: 24px;
				padding-top: 24px;
				height: 200px;
				border-bottom: 1px solid #F4F4F4;
				
				&-item{
					display: flex;
					padding: 4px 0;
					
					div:first-child{
						color: #666;
						width: 120px;
					}
					div:last-child{
						color: #409EFF;
					}
				}
			}
			
			.cr-btm{
				display: flex;
				padding-top: 24px;
				&-item{
					text-align: center;
					width: 33%;
					height: 60px;
					>div:first-child{
						width: 100%;
						display: flex;
						justify-content: center;
					}
					.crb-icon{
						width: 60px;
						height: 60px;
						background: #409EFF;
						color: #fff;
						border-radius: 10px;
						line-height: 60px;
					}
					
					>div:last-child{
						color: #999;
						margin-top: 8px;
					}
				}
			}
		}
	}
	
</style>
